import React from 'react'
import {Link} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const FeaturedItem = ({alt, image, slug, title, date, author, description}) => {
    return (
        <div className="my-2"> 
            <Link className="no-underline" to={`/${slug}`}>
                <GatsbyImage image={image}
                alt={alt} className="my-2 max-h-96"
                />
                  <div className="stack pl-4 my-2">
                    <h3 className="font-header my-2 text-2xl">{title}</h3>
                    {description}
                  </div>
           </Link>
        </div>
    )
}

export default FeaturedItem