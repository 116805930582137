import React from 'react'
import {Link} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const BlogItem = ({alt, image, slug, title, date, author}) => {
    return (
        <div className="my-2 zoom"> 
            <Link to={`/${slug}`}>
                <GatsbyImage image={image}
                alt={alt} className="my-2 max-h-32"
                />
                  <h3 className="font-header text-center my-2 capitalize sm:text-base text-sm">{title}</h3>
           </Link>
        </div>
    )
}

export default BlogItem