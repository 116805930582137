import React from "react"
import Layout from "../components/Layout"
import Blog from "../components/blog"
import SEO from "../components/seo"


const Home = () => {
  return(
    <Layout>
      <SEO title="Friendliest.app Blog" />
      <Blog/>
    </Layout>
  )
}

export default Home